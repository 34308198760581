import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Setup",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker Setup' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Setup/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-setup",
        "aria-label": "iobroker setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Setup`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/MQTT_Client/"
        }}>{`ioBroker MQTT Client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Node-RED_Installation"
        }}>{`Node-RED Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Smartphone_Integration/"
        }}>{`ioBroker Smartphone Integration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/"
        }}>{`Blockly Script Engine`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Cloud_Adapter/"
        }}>{`ioBroker Cloud Adapter`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      